<template>
  <th-page-wrapper>
    <th-datatable
      :key="$route.fullPath"
      ref="resourceTable"
      sortable
      :headers="headers"
      do-route
      do-route-filters
      :locale="locale"
      :show-operations="false"
      :resource-limit="1000"
      resource="deviceGroups"
      :resource-query="{ query: resourceQuery }"
      :meta-options="resourceQuery"
      route-base="/resources/device_groups"
      :buttons="computedButtons"
      show-search
      no-meta-check
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeviceGroupsAll',
  metaInfo() {
    return {
      title: this.$t('common.resource.device_group.plural')
    }
  },
  data() {
    return {
      resourceQuery: {
        deleted: false
      },
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'description',
          label: this.$t('common.headers.description.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'count',
          label: this.$t('pages.device_groups.all.headers.count'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (!row.devices) return '-'
            return row.devices.length
          }
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['resources:device_groups:create']
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale'
    }),
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    refresh() {
      this.$refs.resourceTable.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.device_groups.title')
        })
      })
    }
  }
}
</script>

<style scoped></style>
